@import '../../styles/variables';
@import '../../styles/mixin';

.hero {
  .swipper__hero_image {
    span[class~='swiper-pagination-bullet'] {
      background-color: $swiper-bullet; /* Change the color of the bullet */
    }

    span[class~='swiper-pagination-bullet-active'] {
      background-color: $swiper-bullet-active !important; /* Change the color of the bullet */
    }

    .container__content {
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      padding: 15px;

      @include media(sm) {
        padding: 20px;
      }

      @include media(lg) {
        padding: 20px;
      }
    }

    .content__description {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      @media screen and (max-width: 420px) {
        row-gap: 6px;
      }

      @include media(2xl) {
        row-gap: 16px;
      }

      &__heading {
        font-weight: 700;
        font-size: $text-xs !important;
        line-height: 16px !important;

        @include media(sm) {
          font-size: $text-sm !important;
          line-height: 18px !important;
        }

        @include media(md) {
          font-size: $text-base !important;
          line-height: 20px !important;
        }

        @include media(2xl) {
          font-size: $text-xl !important;
          line-height: 24px !important;
        }
      }

      &__header {
        font-weight: 700;
        font-size: $text-xl !important;
        line-height: 24px !important;
        letter-spacing: -0.01em;

        @media screen and (min-width: 420px) {
          font-size: 1.5rem !important;
          line-height: 30px !important;
        }

        @include media(sm){
          font-size: 2rem !important;
          line-height: 40px !important;
        }

        @include media(xl) {
          font-size: 3rem !important;
          line-height: 60px !important;
        }

        @include media(3xl) {
          font-size: 5rem !important;
          line-height: 96px !important;
        }
      }

      &__descrip {  
        font-weight: 400;
        font-size: $text-sm;
        line-height: 18px;
        letter-spacing: 0.01em;

        @media screen and (max-width: 520px) {
          line-height: 16px;
          font-size: $text-xs;
        }

        @include media(lg){
          font-size: $text-base;
          line-height: 20px;
        }

        @include media(xl){
          font-size: $text-xl;
          line-height: 24px;
        }

        @include media(3xl){
          margin-top: 6px;
          font-size: $text-2xl;
          line-height: 32px;
        }

      }

       &__action {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $text-sm;
          padding: 9px 16px;
          height: 36px;
          width: fit-content;
          min-width: 100px;
          background-color: var(--cta-background-color);
          color: var(--cta-text-color);
          border: var(--border-style);
          border-radius: var(--border-radius);
          font-weight: 500;
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--cta-background-color-hover);
            color: var(--cta-text-color-hover);
            border: var(--border-style-hover);
          }

          @media screen and (max-width: 520px) {
             display: none;
          }

          @include media(sm){
            display: none;
          }

          @include media(md){
            margin-top: 4px; 
          }

          @include media(lg) {
            display: flex;
            height: 48px;
            margin-top: 20px;  
            font-size: $text-base;
            line-height: 22.39px;
            min-width: 160px;
            padding: 8px 16px;
          }
      }


      @media screen and (max-width: 520px) {
        br {
          display: none;
        }
      }

    }
  }

  figure {
    position: relative;
    align-self: center;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    background-color: $gray-50;
    //border: 1px solid $gray-100;

    img {
      height: auto;
    }

    svg {
      position: absolute;
      height: 18px;
      width: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include media(sm) {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__spacing_bottom {
    margin-bottom: $margin-section-mobile;

    @include media(lg) {
      margin-bottom: $margin-section;
    }
  }

  .container__clear {
    @media screen and (max-width: 767px) {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .container__effect_img {
    figure {
      img,
      svg {
        scale: 1;
        transition: 0.8s all ease 0.4s;

        &:hover,
        &:focus-within {
          transition: 0.8s all ease 0s;
          scale: 1.03;
        }
      }
    }
  }

  .container__full_width {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: none !important;

    img {
      border-radius: 0 !important;
    }
  }

  .container__with_margin {
    @include media(md) {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    @include media(lg) {
      padding-bottom: 26px;
      padding-top: 26px;
    }

    @include media(xl) {
      padding-bottom: 32px;
      padding-top: 32px;
    }

    @include media(2xl) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }

  .container__withous_margin {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .container__effect_img {
    figure {
      img,
      svg {
        scale: 1;
        transition: 0.8s all ease 0.4s;

        &:hover,
        &:focus-within {
          transition: 0.8s all ease 0s;
          scale: 1.03;
        }
      }
    }
  }

  .banner__figure {
    position: relative;
    display: none;

    @include media(sm) {
      display: block;
      width: 100%;
      padding-top: 33.33%;
      height: 0;
    }

    img {
      height: auto;
    }
  }

  .banner__figure_mobile {
    position: relative;
    padding-top: 58%;

    img {
      height: auto;
    }

    @include media(sm) {
      display: none;
    }
  }

  img {
    @include media(md) {
      border-radius: $border-radius-md;
    }
  }
}
