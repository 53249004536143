@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  flex-direction: column;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 72px;
    }
  }

  .stats__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 72px;
    row-gap: 40px;

    .stats__pie_chart {
      position: relative;
      width: 260px;
      height: 260px;
      border-radius: 100%;
      background: conic-gradient(var(--pie-colors));

      &:before {
        content: '';
        position: absolute;
        top: 70px;
        left: 70px;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        background-color: $background-theme;
      }
    }

    .stats__list {
      display: grid;
      flex-direction: row;
      grid-template-rows: auto auto;
      grid-template-columns: auto auto;
      column-gap: 32px;
      row-gap: 47px;

      .stats__element {
        display: flex;
        flex-direction: column-reverse;
        width: 138px;

        @include media(xl) {
          width: 209px;
        }

        .stats__element_title {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          padding-left: 40px;
          color: #606e89;
        }

        .stats__value_joint {
          display: flex;
          align-items: center;
          gap: 14px;

          .stats__element_icon {
            width: 24px;
            height: 24px;
            border-radius: 100%;
          }
          .stats__element_value {
            font-size: 40px;
            font-weight: 700;
            line-height: 52px;
            color: #494d54;
          }
        }
      }
    }

    .stats__description {
      max-width: 385px;

      & > p:first-child {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }

      & > p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}
