@import '../../styles/variables';
@import '../../styles/mixin';

.bogo_list {
  margin-bottom: 32px;
  .title {
    font-size: $text-xl !important;
    margin-bottom: 19px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    font-size: $text-base;
    row-gap: 8px;

    .link {
      align-items: center;
      display: flex;
      border-radius: 8px;
      width: 100%;
      column-gap: 12px;
      width: -webkit-fill-available;
      padding: 16px 16px;
      background-color: $active-bogo;
      border: $active-bogo-border;
      color: $active-bogo-text;
      background-image: none;

      span {
        color: $active-bogo-text;
        text-decoration: underline;
      }

      figure {
        position: relative;
        flex-shrink: 0;
      }

      svg {
        transform: scale(1);

        * {
          stroke-width: 2.5;
          stroke: $active-bogo-text;
        }
      }
    }
  }
}

.active_bogo_section {
  position: relative;
  max-width: 360px;
  margin-bottom: 24px;

  @include media(md) {
    max-width: 659px;
  }

  > div::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -6px;
    width: 16px;
    display: block;
    background: linear-gradient(
      270deg,
      $detail-background-product-info 50%,
      transparent
    );
    z-index: 3;
  }

  .bogo_container {
    width: 172px;
    height: 80px;
    position: relative;
    background-image: none;

    @include media(md) {
      width: 198px;
      height: 92px;
    }

    &:hover {
      background-image: none;
    }

    .bogo_card {
      width: 100%;
      height: auto;

      svg {
        transform: scale(1);
        margin-top: 0 !important;
        height: auto;
        width: 100%;

        path[class='bogo-card-content'] {
          fill: $active-bogo-content-background;
        }

        path[class='bogo-card-deal'] {
          fill: $active-bogo-deal-background;
        }
      }
    }

    .bogo_name {
      position: absolute;
      top: 8px;
      left: 8px;
      max-width: 128px;

      @include media(md) {
        top: 12px;
        max-width: 150px;
      }

      span {
        font-size: $text-xs;
        line-height: 1rem;
        font-weight: 500;
        color: $active-bogo-content-text-color;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
    }
    .bogo_link {
      position: absolute;
      bottom: 8px;
      left: 8px;
      display: flex;
      gap: 4px;

      @include media(md) {
        bottom: 12px;
      }

      i {
        color: $active-bogo-link-text-color;
      }

      span {
        font-size: $text-xs;
        line-height: 1rem;
        font-weight: 500;
        color: $active-bogo-link-text-color;
      }
    }

    .bogo_deal_label {
      display: block;
      position: absolute;
      transform: rotate(90deg);
      top: 27px;
      right: 0;
      font-size: $text-xs;
      font-weight: 700;
      color: $active-bogo-deal-text-color;

      @include media(md) {
        top: 38px !important;
        right: 3px;
      }
    }
  }
}
