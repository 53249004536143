@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 72px;
    }
  }

  .stats__list {
    display: flex;
    position: relative;
    align-self: center;
    flex-direction: column;
    align-items: start;
    width: fit-content;
    gap: 80px;
    margin-top: 124px;
    margin-left: 24px;

    @include media(lg) {
      display: grid;
      width: 100%;
      grid-template-areas: var(--vertical-grid-template-areas);
      gap: 0px;
    }

    @include media(xl) {
      display: grid;
      overflow-x: scroll;
      grid-template-areas: var(--horizontal-grid-template-areas);
      grid-auto-columns: 200px;
      justify-content: center;
      height: max-content;
      gap: 24px;
      margin-left: 0px;
      margin-top: 0px;
      padding-bottom: 24px;
    }

    .chronology_line {
      position: absolute;
      width: 4px;
      height: var(--container-height);
      top: -110px;
      left: -15px;
      background: linear-gradient(
        to bottom,
        transparent,
        var(--line-color) 10% 90%,
        transparent
      );

      @include media(lg) {
        position: absolute;
        left: 50%;
        height: var(--container-height);
      }

      @include media(xl) {
        position: relative;
        top: 0px;
        left: 0px;
        grid-area: line;
        width: auto;
        height: 4px;
        background: linear-gradient(
          to right,
          transparent,
          var(--line-color) 10% 90%,
          transparent
        );
      }
    }

    .stats__element {
      display: flex;
      position: relative;
      flex-direction: column;
      padding-left: 24px;
      padding-top: 12px;
      border-top: 1px solid #a1acc2;

      @include media(lg) {
        padding-right: 24px;
        padding-left: 0px;
      }

      @include media(xl) {
        padding-left: 0px;
        padding-top: 0px;
        border-top: none;
        max-width: 395px;
      }

      .stats__element_date {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #62666e;
        margin-bottom: 10px;
      }

      .stats__element_description {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #898c91;
      }
    }

    @for $i from 1 through 20 {
      .stats__element:nth-child(#{$i}) {
        grid-area: element_#{$i};
      }
    }

    .stats__element:nth-child(even) {
      @include media(lg) {
        padding-right: 0px;
        padding-left: 24px;
      }

      @include media(xl) {
        padding-left: 0px;
        margin-bottom: 98px;
        margin-right: -400px;
        margin-left: 150px;

        .stats__element_description {
          max-width: 395px;
        }
      }

      @media screen and (min-width: 2047px) {
        margin-right: -500px;
        margin-left: 150px;

        .stats__element_description {
          max-width: 395px;
        }
      }

      &:before {
        @include media(xl) {
          content: '';
          position: absolute;
          left: -24px;
          top: -12px;
          width: 395px;
          height: 1px;
          background-color: #a1acc2;
          width: 1px;
          height: 222px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: -24px;
        top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background-color: var(--line-color);
        z-index: 1;

        @include media(lg) {
          left: -10px;
        }

        @include media(xl) {
          left: -35px;
          top: 211px;
        }
      }
    }

    .stats__element:nth-child(odd) {
      @include media(xl) {
        margin-top: 96px;
        margin-right: -400px;
        margin-left: 150px;

        .stats__element_description {
          max-width: 395px;
        }
      }

      @media screen and (min-width: 2047px) {
        margin-right: -500px;
        margin-left: 150px;

        .stats__element_description {
          max-width: 395px;
        }
      }

      &:before {
        @include media(xl) {
          content: '';
          position: absolute;
          left: -24px;
          top: -12px;
          width: 395px;
          height: 1px;
          background-color: #a1acc2;
          left: -24px;
          top: -120px;
          width: 1px;
          height: 222px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: -24px;
        top: -12px;
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background-color: var(--line-color);
        z-index: 1;

        @include media(lg) {
          left: auto;
          right: -14px;
        }

        @include media(xl) {
          left: -35px;
          top: -133px;
        }
      }
    }
  }
}
