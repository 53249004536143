@import '../../styles/variables';
@import '../../styles/mixin';

.customwidthsidebyside__container {
  display: grid;
  margin-bottom: 30px;

  .section {
    border-radius: $border-radius-md;
    display: grid;
    gap: 20px;
    padding: 24px 40px;
    place-self: center;

    @include media(sm) {
      padding: 44px 40px;
      gap: 40px;
    }

    @include media(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(xl) {
      gap: 90px;
      padding: 64px 110px;
    }

    @include media(2xl) {
      gap: 104px;
      padding: 64px 124px;
    }

    @include media(3xl) {
      gap: 144px;
      padding: 86px 164px;
    }

    .left__content {
      display: flex;
      align-items: center;
      justify-content: center;

      &_image_background {
        min-height: 360px;
        background: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 24px 20px;
        height: auto;

        h2,
        h3,
        h4,
        p {
          color: #ffffff;
        }

        @include media(xl) {
          padding: 40px;
        }
      }

      &_image_border_rounder {
        border-radius: $border-radius-md;
      }
    }

    .section_content {
      display: grid;
      position: relative;
      margin-bottom: 0;

      h2,
      h3,
      h4 {
        margin-bottom: 16px;
        width: 100%;
        word-wrap: break-word;
      }

      p {
        margin-bottom: 25px;
        display: grid;
        place-self: center;

        a {
          font-weight: 700;
          transition: color ease 0.2s;
          color: $primary-400;
        }

        a:hover {
          color: $primary-200;
        }
      }

      &_image_border_rounder {
        border-radius: $border-radius-md;
      }

      *:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
