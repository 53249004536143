@import '../../../styles/variables';
@import '../../../styles/mixin';

.stats__container {
  display: flex;
  flex-direction: column;

  .stats__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #494d54;
    margin-bottom: 40px;
    max-width: 600px;

    @include media(sm) {
      font-size: 32px;
      line-height: 40px;
      color: #494d54;
      margin-bottom: 64px;
    }
  }

  .stats__list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 40px;

    .stats__element {
      display: flex;
      min-width: 132px;
      flex-direction: column-reverse;

      .stats__element_title {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #898c91;
      }

      .stats__element_value {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        color: #494d54;
      }
    }
  }
  .stats__bottom_description {
    margin-top: 48px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    max-width: 800px;
    color: #898c91 !important;
  }
}
