@import '../../styles/variables';
@import '../../styles/mixin';

.image_with_title {
  display: flex;
  align-items: center;
}

.container {
  position: relative;

  .body {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;

    @include media(lg) {
      max-width: 578px;
    }

    @include media(xl) {
      max-width: 724px;
    }

    @include media(2xl) {
      max-width: 868px;
    }

    @include media(3xl) {
      max-width: 1158px;
    }

    .body__title {
      font-size: calc(1.4rem + 1.5vw);
      line-height: calc(1.8rem + 1.5vw);
      font-weight: 700;
      color: $gray-500;

      @include media(2xl) {
        font-size: $text-5xl;
      }
    }

    p {
      font-size: $text-lg;
      color: $gray-400;
      margin-bottom: 8px;
      order: 2;

      @include media(sm) {
        margin-bottom: 48px;
      }
    }

    .body__figure {
      position: relative;
      min-height: 190px;
      width: 100%;
      overflow: hidden;
      border-radius: $border-radius-md;
      background-color: $gray-50;
      order: 3;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: $gray-300;
      }

      @media screen and (max-width: 420px) {
        min-height: 145px;
      }

      @include media(sm) {
        min-height: 288px;
      }

      @include media(md) {
        min-height: 346px;
      }

      @include media(lg) {
        min-height: 260px;
      }

      @include media(xl) {
        min-height: 326px;
      }

      @include media(2xl) {
        min-height: 391px;
      }

      @include media(3xl) {
        min-height: 522px;
      }
    }

    &__heading {
      .body__title {
        order: 1;
        margin-bottom: 24px;
      }

      p {
        order: 4;
        margin: 24px 0 27px;

        @include media(sm) {
          margin: 40px 0 43px;
        }
      }

      .body__text_heading {
        font-size: $text-lg !important;
        font-weight: 600;
        color: $gray-300;
        order: 0;
        letter-spacing: 2%;
      }

      a {
        order: 5;
        color: $label-btn-link;
        padding: 11px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $text-sm;
        line-height: 18px;
        font-weight: 600;
      }

      .body__button_primary {
        text-transform: uppercase;
        display: block;
        padding: 11px 16px;
        border-radius: $border-radius-btn;
        height: 40px;
        font-weight: 500;
        background: $btn-primary;
        color: $label-btn-primary;
      }
    }
  }
}
