@import '../../styles/variables';
@import '../../styles/mixin';

.gallery__image_row {
  display: flex;
  align-items: center;

  .gallery__images {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;

    @include media(sm) {
      gap: 24px;
    }

    @include media(md) {
      width: 78%;
    }

    @include media(lg) {
      width: 67%;
    }

    @include media(xl) {
      width: 1272px;
    }

    &_comparison_slider {
      flex-direction: column !important;
      width: 100% !important;
      height: auto !important;
    }

    .gallery_image__item {
      position: relative;
      flex-grow: 1;
      flex-basis: 45%;
      max-width: 45%;

      @media screen and (min-width: 480px) {
        flex-basis: 33.33%;
        max-width: 138px;
      }

      @include media(sm) {
        max-width: 168px;
      }

      @include media(lg) {
        max-width: 200px;
      }

      @include media(xl) {
        max-width: 250px;
      }

      @include media(2xl) {
        max-width: 270px;
      }

      img {
        border-radius: $border-radius-md;
        height: auto;
      }
    }
  }
}
