@import '../../../styles/variables';
@import '../../../styles/mixin';


.title {
  font-size: $text-2xl !important;
  line-height: 32px !important;
  color: $gray-500;

  @include media(lg) {
    font-size: 2rem !important;
    line-height: 40px !important;
  }

  @include media(2xl) {
    font-size: $text-4xl !important;
    line-height: 44px !important;
  }
}

.content__header_description {
  font-size: $text-sm;
  padding-top: 12px;
  color: $gray-400;
  line-height: 20px;
  order: 2;

  @include media(lg){
    font-size: $text-base;
  }
}

.image__comparison_container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  justify-items: center;
  grid-auto-flow: dense;
  row-gap: 32px;
  width: 100%;

  @include media(xl) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    margin: auto;
    grid-gap: 32px;
  }

  .image__comparison_item {
    height: auto;
    flex-grow: 1;
    max-width: var(--icons-width);
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

    .images {
      border-radius: 12px; 
      overflow: hidden;
    }

    .label {
      font-size: .75rem;
      position: absolute;
      padding: .375em .75rem;
      color: #0d0d0d;
      border-radius: $border-radius-sm;
      backdrop-filter: blur(0.25rem) saturate(180%) contrast(80%) brightness(120%);
      Webkit-backdrop-filter: blur(0.25rem) saturate(180%) contrast(80%) brightness(120%);
      transition: opacity 0.25s ease-in-out;
      bottom: 1rem;
      min-width: 56px;
    }
  }
}