@import '../../styles/variables';
@import '../../styles/mixin';

.discounts {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 20px 0;
  margin-bottom: 16px;
  background: inherit;
  z-index: 2;

  @include media(md) {
    padding: 0 0 16px;
  }

  .discounts__header {
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-weight: 500;
    font-size: $text-base;
    font-family: var(--font-family);
    padding: 0 0 10px 5px;

    i {
      color: $badge;
    }
  }

  .discounts__title {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
  }

  .discount_header__badge {
    padding: 3px 8px;
    margin-bottom: 0.125rem;
    margin-bottom: 0;

    i {
      margin-right: 3px;
    }
  }

  .discounts__type {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 10px;

    @include media(md) {
      margin-right: 12px;
    }
  }

  .discount__content_paceholder {
    display: flex;
    flex-direction: column;
    padding: 0 6px;
    margin-right: 12px;
  }

  section:first-child {
    padding-top: 0 !important;
  }

  .discounts__item {
    padding: 5px 10px 5px 0;
    display: flex;
    column-gap: 4px;
    align-items: center;
    font-family: var(--font-family);
    // color: $gray-500;
    font-size: $text-sm;
    font-weight: 500;
    align-self: center;

    @include media(md) {
      padding-left: 0;
    }

    i {
      padding-top: 4px;
      align-self: baseline;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
