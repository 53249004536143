@import '../../styles/variables';
@import '../../styles/mixin';

.discounts {
  padding-bottom: 12px;
  margin: 0 auto;

  @include media(sm) {
    margin: 0;
    margin-top: 36px;
    margin-bottom: 10px;
    // padding-right: 12px;
  }

  .discounts__panel {
    width: 92vw;
    max-width: 400px;
    max-height: 200px;
    border-radius: 4px;
    background-color: $white;
    position: absolute;
    bottom: 50px;
    border: 0.5px solid #cdd4d5;
    z-index: 4;
    padding: 14px 0 16px;
    left: 50%;
    color: $gray-600;
    transform: translate(-50%, 5px);

    @include media(sm) {
      right: 0;
      left: 0;
      transform: translate(-55%, 5px);
    }

    &_large {
      @include media(sm) {
        right: 0;
        left: 0;
        transform: translate(-20%, 5px);
      }
    }

    .discounts__title {
      color: $label-btn-link;
      font-size: $text-base !important;
      line-height: 18px;
      padding-left: 14px;
      margin-bottom: 10px;
      font-weight: 500;
    }

    .discounts__list {
      max-height: 120px;
      overflow-y: auto;

      @include media(sm) {
        &::-webkit-scrollbar {
          width: 4px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
        border-radius: 8px;
      }
    }
  }

  ul,
  li {
    list-style-type: none;
  }

  .discounts__item {
    padding: 5px 10px 5px 16px;
    display: flex;
    column-gap: 4px;
    align-items: center;
    // color: $gray-400;
    font-size: $text-sm;
    font-family: var(--font-family);
    font-weight: 500;
    align-self: center;

    i {
      padding-top: 1px;
      align-self: baseline;
      color: $label-btn-link;
    }
  }

  .peak {
    background: $white;
    bottom: -7px;
    border-right: 0.5px solid $gray-100;
    border-bottom: 0.5px solid $gray-100;
    height: 12px;
    position: absolute;
    transform: rotateZ(45deg);
    right: 47%;
    width: 12px;

    @include media(sm) {
      right: 22%;
    }

    &_large {
      @include media(sm) {
        right: 37%;
      }
    }
  }

  .discounts_badge {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 6px;
    height: 32px;
    //background: $background-bundle-popup;
    color: $white;
    border-radius: 16px;
    padding: 8px 16px;
    /*  outline: $primary 1px dotted;
        outline-offset: 1px; */
    border-width: 1px;
    text-transform: none !important;

    .discounts_badge__underline {
      font-size: $text-sm;
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: 4px 1px;
      background-image: linear-gradient(to right, #ffffff 50%, transparent 50%);
      cursor: pointer;
      padding: 0;
    }
  }
}
