@import '../../styles/variables';
@import '../../styles/mixin';

.sidebyside__container {
  .section {
    display: grid;
    margin-bottom: 30px;
    padding: 24px 20px;
    column-gap: 20px;

    @include media(sm) {
      padding: 44px 40px;
      column-gap: 40px;
    }

    @include media(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(xl) {
      padding: 64px 90px;
      column-gap: 90px;
    }

    @include media(2xl) {
      padding: 64px 104px;
      column-gap: 104px;
    }

    @include media(3xl) {
      padding: 86px 144px;
      column-gap: 144px;
    }

    &__border_rounder {
      border-radius: $border-radius-md;
    }

    &__img_border_rounder {
      .section_content p img {
        border-radius: $border-radius-md;
      }
    }

    .left_content {
      @include media(xl) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    .right_content {
      @include media(xl) {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }

    .section_content {
      place-self: center;
      max-width: 50rem;
      margin-bottom: 0;

      p {
        display: grid;

        img {
          margin: 20px 0;
          width: 100%;
        }
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
