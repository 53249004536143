@import '../../../styles/variables';
@import '../../../styles/mixin';

.hero__custom_dimensions {
  position: relative;

  &_margin {
    margin-bottom: 10.67px;

    @include media(sm) {
      margin-bottom: 40.39px;
    }
  }

  &--fullWidth {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .custom_dimensions_container {
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .content {
    display: grid;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    padding: 4.5% 15px;
    top: 0;
    width: 100%;

    @media screen and (max-width: 520px) {
      padding: 0;
     
      br {
        display: none;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      padding: 0 15px;
      width: initial;
      z-index: 1;

      @include media(sm) {
        padding: 0;
      }

      &_large {
        width: 100% !important;

        @include media(sm) {
          width: auto !important;
        }

        p {
          display: flex;
          flex-direction: column;
          text-align: end;
          font-size: calc(8px + 2vw);
          line-height: calc(14px + 2vw);

          @include media(sm) {
            text-align: start;
            font-size: calc(2px + 2vw);
            line-height: calc(3px + 2vw);
          }

          @include media(lg) {
            line-height: 20px;
            font-size: 18px;
          }

          br {
            display: none;
          }
        }
      }

      &__action {
        font-size: $text-sm;
        letter-spacing: 0.8px;
        padding: 6.5px 14px;
        width: max-content;
        min-width: 100px;
        background-color: var(--cta-background-color);
        color: var(--cta-text-color);
        border: var(--border-style);
        border-radius: var(--border-radius);
        text-align: center;
        font-weight: bold;
        transition: all 0.3s ease;

        &:hover {
          background-color: var(--cta-background-color-hover);
          color: var(--cta-text-color-hover);
          border: var(--border-style-hover);
        }

        @include media(sm) {
          font-size: $text-base;
          line-height: 22px;
          min-width: 140px;
          padding: 8px 16px;
        }

        @include media(lg) {
          padding: 9px 30px;
          min-width: 160px;
        }

        &_hidden_mobile {
          @media screen and (max-width: 520px) {
            display: none;
          }
        }
      }

      &__descrip {
        letter-spacing: 0.64px;
        line-height: 16px;
        font-size: 12px;
        padding-bottom: 10px;

        @include media(sm) {
          line-height: 26px;
          font-size: 18px;
        }

        @include media(lg) {
          line-height: 28px;
          font-size: 20px;
        }
      }

      &__heading {
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 10px;
        opacity: 0.8;

        @include media(sm) {
          line-height: 24px;
          font-size: 12px;
        }

        @include media(lg) {
          line-height: 26px;
          font-size: 14px;
        }
      }

      &__header {
        font-weight: 600;
        letter-spacing: 1px;
        font-size: calc(1.5px + 6vw);
        line-height: calc(8px + 6vw);
        padding-bottom: 10px;

        @include media(sm) {
          font-size: calc(1px + 4vw);
          line-height: calc(8px + 4vw);
        }

        @include media(xl) {
          font-size: 52px;
          line-height: 64px;
        }
      }
    }
  }
}
